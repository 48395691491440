import styled from 'styled-components'

export const Container = styled.div `
    margin-top: 20px;
    width: 100%;
    height: 80%;
    display: flex;
    align-content: center;
    align-items: center;
    justify-content: center;
    flex-direction: column;

    background: #4C966A;
    border: 1px solid #000000;
    border-radius: 10px;
`

export const Text = styled.h1`
    font-weight: 400;
    font-size: 14.964px;
    line-height: 20px;
    text-align: center;
    color: #FFFFFF;
    margin-bottom: 15px;
    margin-top: -25px;
`

export const Label = styled.label `
    display: flex;
    flex-direction: column;
    font-weight: 400;
    font-size: 14.964px;
    line-height: 20px;
    text-align: start;
    color: #FFFFFF;
    margin-bottom: 20px;
`

export const Input = styled.input`
    width: 293px;
    height: 50px;
    background: #F3F2F2;
    border-radius: 10px;
    border: none;
    padding: 10px;
    outline: none;
    border: ${ props => (props.error ? '1px solid #cc1717' : 'none')};
`

export const Button = styled.button `
    width: 293px;
    height: 50px;
    background: #087B51;
    border-radius: 10px;
    border: none;
    margin-bottom: 30px;
    font-weight: 700;
    font-size: 14.964px;
    line-height: 20px;
    text-align: center;
    opacity: 0.9;
    color: #F3F2F2;
    cursor: pointer;

    &:hover {
        opacity: 1.5;
    }
`

export const ErrorMessage = styled.p `
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 16px;
    color: #cc1717;
    margin-top: 5px;
    text-align: center;
`

export const AlbertLogo = styled.img `
    position: relative;
    bottom: 35px;
`