import React, { useEffect, useState } from 'react';
import { Widget, addResponseMessage, toggleInputDisabled, addUserMessage, setQuickButtons, toggleMsgLoader, renderCustomComponent, deleteMessages } from 'react-chat-widget';
import MyResponseComponent from '../../components/customComponent'
import SupportAgentIcon from '@mui/icons-material/SupportAgent';

import 'react-chat-widget/lib/styles.css';
import logo from '../../images/albert.svg'
import { SocketContext } from '../../hooks/socket';
import AsyncComponent from '../../components/asyncComponent';
import { v4 as uuidv4 } from 'uuid';

//conexao com web-socket
localStorage.setItem('LoadedPage', JSON.stringify({
    load: true
}))

const { io } = require('socket.io-client')
const socket = io.connect('https://websocketchat.radinfo.com.br', { transports: ['websocket'], upgrade: false, reconnection: true })
//https://websocketchat.radinfo.com.br

function ChatAlbert() {    

    const { setLoadPage, loadPage } = React.useContext(SocketContext)

    const [status, setStatus] = useState('')

    //callback de resposta do albert
    useEffect(() => {

        function SocketConnection(){
            socket.on("connect", () => {

                localStorage.setItem('userId', Math.floor(Math.random() * 129391929121))
                localStorage.setItem('socketId', socket.id)
                
                // vai chamar toda vez que estabelecar uma nova conexao, e tiver um numero de contato salvo
                if (JSON.parse(localStorage.getItem('client-albert')) !== null) {
                    
                    if(JSON.parse(localStorage.getItem('LoadedPage')).load === true){
    
                        handleNewUserMessage('')
                        socketVerify()
    
                        renderCustomComponent(AsyncComponent, {
                            label: 'async component',
                            value: 1
                        })
    
                        //setStatus(
                            //<Box sx={{ width: 200 }}>
                                //<Skeleton animation="wave" height={30} />
                            //</Box>
                        //)
                    }
    
                } else {
                    toggleInputDisabled()
                    renderCustomComponent(MyResponseComponent, {
                        label: 'custom component',
                        value: 2
                    })
                }
    
                socket.on("chat", (data) => {
    
                    toggleMsgLoader(true)
                    //console.log(data)

                    if(data.output !== undefined){
                        if(['intents'] in data.output){
                            if(data.output.intents[0].intent === 'salvarnumero'){
                                localStorage.setItem('InvalidNumber', true)
                            }
                        }
                    }

                    if(data.organization !== undefined) setStatus(data.organization)
                    
                    localStorage.setItem('organization', data.organization)

                    if(JSON.parse(localStorage.getItem('LoadedPage')).load === true){
                        localStorage.setItem('LoadedPage', JSON.stringify({ load: false }))

                        deleteMessages()
                        
                    }
    
                    setTimeout(() => {
                        
                        let btn_initial = 0
                        let bnt_options = 0
                        let bnt_options2 = 0
                        let buttonList = []
    
                        toggleMsgLoader(false)
    
                        if (['output'] in data) {
                            const albert = data.output.generic
                            while (btn_initial < albert.length) {
    
                                if (['title'] in albert[btn_initial]) {
                                    addResponseMessage("" + data.output.generic[btn_initial].title)
                                }
    
                                if (['text'] in albert[btn_initial]) {
                                    addResponseMessage("" + data.output.generic[btn_initial].text)
                                }
    
                                btn_initial++
                            }
    
                            while (bnt_options < albert.length) {
    
                                if (['options'] in albert[bnt_options]) {
                                    while (bnt_options2 < albert[bnt_options].options.length) {
    
                                        buttonList.push({
                                            label: albert[bnt_options].options[bnt_options2].label,
                                            value: albert[bnt_options].options[bnt_options2].value.input
                                        })
    
                                        setQuickButtons(buttonList)
                                        bnt_options2++
                                    }
                                }
                                bnt_options++
                            }
    
    
                        } else {
                            if (JSON.stringify(data) === `{}`) {
                                //console.log('msg encaminhada para o helpdesk')
                            } else {
    
                                // verificando chave primaria
                                if (['error'] in data) {
                                    //console.log('ocorreu um erro: ' + data.error)
                                } else {
                                    toggleMsgLoader()
                                    if (data.body === undefined) {
    
                                    } else {
                    
                                        setTimeout(() => {
                                            toggleMsgLoader()
                                            var target = data.body
                                            var pattern = /^(\[(.*)\]:)(\s+)?/gi
                                            addResponseMessage("" + data.body.replace(/^(\[(.*)\]:)(\s+)?/gi, pattern.exec(target)[2] + ': '))
                                        }, 3000)
                                    }
                                }
    
                            }
                        }
                    }, 2000)
    
                })
            })
        }

        SocketConnection()
    }, []);

    useEffect(() => {
        if(loadPage === true){
            handleNewUserMessage('')
            socketVerify()

            renderCustomComponent(AsyncComponent, {
                label: '',
                value: ''
            })

            toggleInputDisabled()
            setLoadPage(false)
        }
    }, [loadPage])

    //opcoes de botoes
    const handleQuickButtonClicked = (message) => {
        handleNewUserMessage(message)
        addUserMessage(message)
        setQuickButtons([])
    }

    //montando json e enviando mensagem
    const handleNewUserMessage = (newMessage) => {

        const data = localStorage.getItem('client-albert')

        if(localStorage.getItem('InvalidNumber') === 'true'){
            if(newMessage !== ''){
                const contactUser = JSON.parse(data)
                contactUser.contact = newMessage

                //console.log(JSON.parse(data))
                localStorage.setItem('client-albert', JSON.stringify(contactUser))
                localStorage.setItem('InvalidNumber', false)

                const body = {
                    "messages": [{
                        "id": uuidv4(),
                        "body": '',
                        "senderName": JSON.parse(data).name,
                        "time": 123,
                        "authorId": localStorage.getItem('userId'),
                        "socketId": localStorage.getItem('socketId'),
                        "phone": newMessage,
                        "type": "chat",
                        "channel": "site",
                    }],
                    "instanceId": 29052000
                };
        
                socket.emit("chat", body)
                //console.log(body)
                setQuickButtons([])
                return true
            }
        }

        const body = {
            "messages": [{
                "id": uuidv4(),
                "body": newMessage,
                "senderName": JSON.parse(data).name,
                "time": 123,
                "authorId": localStorage.getItem('userId'),
                "socketId": localStorage.getItem('socketId'),
                "phone": JSON.parse(data).contact,
                "type": "chat",
                "channel": "site",
            }],
            "instanceId": 29052000
        };

        socket.emit("chat", body)
        //console.log(body)
        setQuickButtons([])
    }

    //socket para recarregar as conversas
    function socketVerify(){
        socket.on("verify", (data) => {
            //console.log(data)

            deleteMessages(1)
    
            let count = 0
    
            if (data.body === undefined) {
    
            } else {
                addResponseMessage(data.body)
            }
    
            while (count < data.verify.length) {
    
                let mensagens = data.verify[count]
    
                if (mensagens.includes(']:')) {
                    var target = data.verify[count]
                    var pattern = /^(\[(.*)\]:)(\s+)?/gi
                    //console.log(localStorage.getItem('organization'))
                    addResponseMessage('' + mensagens.replace(/^(\[(.*)\]:)(\s+)?/gi, pattern.exec(target)[2] + ': '))
                    //setStatus(ConsultorName(pattern.exec(target)[2]))
                } else {
                        //identificar a mensagem de solicitacao de abertura
                        if(mensagens.includes('Solicitante:')){
                            addResponseMessage(mensagens)
                        }else{
                        addUserMessage(mensagens.replace(/^(\[(.*)\]:)(\s+)?/gi, '.'))
                        }
                }
                
                count++
            }
        })
    }

    function ConsultorName(name){
        return (
            <div style={{ display: 'flex', alignItems: 'center', gap: 5}}>
                <SupportAgentIcon /> {name}
            </div>
        )
    }

    function randomIds(){
        //em caso de nao existir uma id armazenada

        let idMensage = 'site-' + Math.floor(Math.random() * 9993750818)
        if(localStorage.getItem('lastID') === null){
            localStorage.setItem('lastID', idMensage)
            return localStorage.getItem('lastID')
        }

        let newIdMessage = 'site-' + Math.floor(Math.random() * 9993750818)

        while (localStorage.getItem('lastID') === newIdMessage) {
            localStorage.setItem('lastID', 'site-' + Math.floor(Math.random() * 9993750818))
            //console.log('id repetida')
            return newIdMessage
        }
        
        return newIdMessage
    }

    socket.on("disconnect", (reason) => {
        //console.log(reason)
      });

    return (
        <div className="App">
            
            <Widget
                toggleMsgLoader={handleNewUserMessage}
                handleNewUserMessage={handleNewUserMessage}
                handleQuickButtonClicked={handleQuickButtonClicked}
                profileAvatar={logo}
                title="Albert - Chat"
                subtitle={status}
                senderPlaceHolder="Mensagem"
                titleAvatar={logo}
                emojis={true}
                autofocus={true}
                showBadge={true}
                showTimeStamp={false}
                sendButtonAlt="Enviar"
            />

        </div>
    );
}

export default ChatAlbert;