import { createGlobalStyle } from 'styled-components'
import 'react-toastify/dist/ReactToastify.css'
import sendButton from '../images/sendButton.svg'

export default createGlobalStyle `
    * {
        margin: 0;
        padding: 0;
        box-sizing: border-box;
        font-family: 'Roboto', sans-serif;
        font-family: 'Roboto Condensed', sans-serif;
        outline: none;
    }

    .rcw-send::before {
        content: url(${sendButton});
        width: 20px;
        height: 20px;
    }

    .rcw-picker-btn .rcw-send-icon, .rcw-send .rcw-send-icon {
        height: 25px;
        display: none
    }

    .quick-buttons-container::-webkit-scrollbar-thumb {
        background: #dcd9d2; 
        border-radius: 10px;
      }
`