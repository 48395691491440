import React from 'react';
import ReactDOM from 'react-dom';
import App from './containers/chat';
import GlobalStyles from './styles/globalStyles';
import './style.css'
import { SocketProvider } from './hooks/socket';

// Find all widget divs
const widgetDivs = document.querySelectorAll('.nicoraynaud-finance-widget');

// Inject our React App into each class
widgetDivs.forEach(div => {
    ReactDOM.render(
      <SocketProvider>
      <React.StrictMode>
        <App symbol={div.dataset.symbol}/>
        <GlobalStyles />
      </React.StrictMode>
      </SocketProvider>,
        div
    );
});