import React from "react";

import CircularProgress from '@mui/material/CircularProgress';

import { Container } from "./styles";

function AsyncComponent(props){

    return (
        <Container>
             <CircularProgress size={35}>a</CircularProgress>
        </Container>
    )
}

export default AsyncComponent