import React, { useState } from "react";

export const SocketContext = React.createContext({})

export const SocketProvider = (props) => {

    const [loadPage, setLoadPage] = useState(false)
    const [attendance, setAttendance] = useState(false)
    const [asyncLoad, setAsyncLoad] = useState(false)

   return (
    <SocketContext.Provider value={{ loadPage, setLoadPage, attendance, setAttendance, asyncLoad, setAsyncLoad }} >
        {props.children}
    </SocketContext.Provider>
   )
}