import React from "react";

import * as Yup from "yup"
import { useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'

import { deleteMessages } from 'react-chat-widget'
import { SocketContext } from '../../hooks/socket'

import { Container, Input, Text, Label , Button, ErrorMessage, AlbertLogo } from './styles'
import ReactionAlbert from '../../images/reaction.svg'


function CustomComponent(props){

    const { setLoadPage, loadPage } = React.useContext(SocketContext)

    const schema = Yup.object().shape({
        nome: Yup.string('Informe seu nome').required('O nome é obrigatorio'),
        numero: Yup.string().required('numero é obrigatorio').required('O numero é obrigatorio')
    })

    const { register, handleSubmit, formState: {errors} } = useForm({ resolver: yupResolver(schema) })

    const onSubmit = clientData => {
        const saveData = {"contact": clientData.numero, "name": clientData.nome }
        localStorage.setItem("client-albert", JSON.stringify(saveData))

        deleteMessages(1) // remover component form
        setLoadPage(true) // true
    }

    return (
        <Container>
            <AlbertLogo src={ReactionAlbert} />
            <Text>Olá, tudo bem?<br />Para iniciarmos o atendimento<br />precisamos do seu nome e contato.</Text>
            <form noValidate onSubmit={handleSubmit(onSubmit)}>
                <Label> Seu nome
                    <Input
                        type="text" 
                        error={errors.nome?.message} { ...register('nome')}
                     />
                     <ErrorMessage>{errors.nome?.message}</ErrorMessage>
                </Label>

                <Label> Seu contato
                    <Input
                     type="text" 
                     error={errors.numero?.message} { ...register('numero')}
                     />
                     <ErrorMessage>{errors.numero?.message}</ErrorMessage>
                </Label>

                <Button type="submit">Pronto! Iniciar atendimento</Button>
            </form>
        </Container>
    )
}

export default CustomComponent